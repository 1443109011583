import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { CalendarService } from "src/app/service/calendar.service";
import { ProcedureService } from "src/app/service/procedure.service";
import { ServiceService } from "src/app/service/service.service";

@Component({
  selector: "modal-telemedicine-credits-settings",
  templateUrl: "./modal-telemedicine-credits-settings.component.html",
  styleUrls: ["./modal-telemedicine-credits-settings.component.css"],
})
export class ModalTelemedicineCreditsSettingsComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private serviceService: ServiceService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;
  title: string = "";

  credits: string;

  validator = new ValidatorUtils();

  ngOnInit() {

  }


  getForms() {}

  insertRecord() {
    this.openMessage("Por favor, aguarde", "", true);

    this.companyService
      .addCreditToCompany(Number(this.credits) * 2.5, this.credits)
      .subscribe(
        (data) => {
          this.modalRef2.hide();
          // this.openMessage("Sucesso!", "Tipo de atendimento criado", false);
          console.log(data);
          if (data && data.url) {
            this.modalRef.hide();
            window.open(data.url);
            this.action.next(data);
          } else {
            this.error(null, "criar");
          }
        },
        (error) => {
          this.modalRef2.hide();
          this.error(error, "criar");
        }
      );
  }

  error(error, string) {
    var msg = "";
    if (
      error &&
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível criar um link de pagamento.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  createRecord() {
    if (
      this.validator.isEmptyOrNull(this.credits) ||
      isNaN(Number(this.credits)) ||
      !Number.isInteger(this.credits)
    ) {
      this.openMessage(
        "Por favor,",
        "o valor total de créditos deve ser um número inteiro.",
        false
      );
      return false;
    }
    this.insertRecord();
  }

  toDecimal(val) {
    if (!val) return val;

    return String(val).replace(",", ".");
  }
  isDecimal(val) {
    val = this.toDecimal(val);
    val = Number(val);
    if (isNaN(val)) return false;
    if (typeof val !== "number") return false;
    return true;
  }

  getTotalToPay(credits) {
    if (!credits || isNaN(Number(credits)) || !Number.isInteger(credits)) {
      return "0,00";
    }
    return (Number(credits) * 2.5).toFixed(2).replace(".", ",");
  }

  getTotalMinutes(credits) {
    if (!credits || isNaN(Number(credits)) || !Number.isInteger(credits)) {
      return "0";
    }
    return Number(credits) * 10;
  }
}
