import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { UserService } from "../service/user.service";
import { ModalPatientDetailComponent } from "../modals/modal-patient-detail/modal-patient-detail.component";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalPatientMedicationDetailComponent } from "../modals/modal-patient-medication-detail/modal-patient-medication-detail.component";
import { ModalNewPatientMedicationComponent } from "../modals/modal-new-patient-medication/modal-new-patient-medication.component";
import { DoctorService } from "../service/doctor.service";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { MatSort } from "@angular/material/sort";
import { ModalPatientResponsablesComponent } from "../modals/modal-patient-responsables/modal-patient-responsables.component";
@Component({
  selector: "app-patients",
  templateUrl: "./patients.component.html",
  styleUrls: ["./patients.component.css"],
})
export class PatientsComponent {
  isSecretary = false;
  constructor(
    private router: Router,
    private userService: UserService,
    private modalService: MDBModalService,
    private doctorService: DoctorService
  ) {

    const d = localStorage.getItem("doctor");
    if (d) {
      const aux = JSON.parse(d);
      if(aux.specialty === 23)
        this.isSecretary = true;
    }
  }
  company_type = localStorage.getItem('companyType')
  displayedColumns: string[] = [
    "name",
    "birthDate",
    "gender",
    "association",
    "active",
    "option",
  ];
  data_source = new MatTableDataSource<any>([]);

  modalRef: MDBModalRef;
  modalRef2: MDBModalRef;

  /* pagination related variabels */
  page: number = 1;
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 50;

  is_loaded: boolean = false;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  filter = true;
  filterText = "";
  allData = [];
  ngOnInit() {
    if (localStorage.getItem("token") == null) {
      this.router.navigate(["/login"]);
    } else if (
      localStorage.getItem("admin") == "true" &&
      localStorage.getItem("company") == null
    ) {
      this.router.navigate(["/home/doctors"]);
    } else {
      this.getPatients(1);
    }
  }

  getPatients(page, search = null) {
    this.userService.getPatientsPagination(page, this.filter).subscribe(
      (data) => {
        this.is_loaded = true;
        // //console.log(data.results)

        // pagination calc
        data = data.filter((item) => item.is_doctor === false)
        this.page = page;
        this.count_total = data.length;
        this.page_size = 50;

        try{
          localStorage.setItem("patients", JSON.stringify(data));
        } catch {
          
        }
        

        // console.log(this.page, this.count_total, this.page_size)

        this.page_count1 = 1 + (page - 1) * this.page_size;
        if (this.page_count1 + (this.page_size - 1) > this.count_total) {
          this.page_count2 = this.count_total;
        } else {
          this.page_count2 = this.page_count1 + (this.page_size - 1);
        }
        // console.log(data);
        data.sort((a, b) => a.name.localeCompare(b.name));
        this.allData = data;
        this.data_source.data = data.slice(
          this.page_count1 - 1,
          this.page_count2
        );
        // console.log(this.page_count1, )
        this.data_source.sort = this.sort;
      },
      (error) => {
        localStorage.removeItem("token");
        this.router.navigate(["/login"]);
      }
    );
  }

  next() {
    if (this.page_count2 < this.count_total && this.is_loaded) {
      // this.data_source.data = [];
      // this.data_source.sort = this.sort;
      // this.is_loaded = false;
      this.page = this.page + 1;
      this.page_count1 = 1 + (this.page - 1) * this.page_size;
      if (this.page_count1 + (this.page_size - 1) > this.count_total) {
        this.page_count2 = this.count_total;
      } else {
        this.page_count2 = this.page_count1 + (this.page_size - 1);
      }
      this.data_source.data = this.allData.slice(
        this.page_count1 - 1,
        this.page_count2
      );
      this.data_source.sort = this.sort;
      // this.getPatients(this.page + 1);
    }
  }

  prev() {
    if (this.page > 1 && this.is_loaded) {
      // this.data_source.data = [];
      // this.data_source.sort = this.sort;
      this.page = this.page - 1;
      this.page_count1 = 1 + (this.page - 1) * this.page_size;
      if (this.page_count1 + (this.page_size - 1) > this.count_total) {
        this.page_count2 = this.count_total;
      } else {
        this.page_count2 = this.page_count1 + (this.page_size - 1);
      }
      this.data_source.data = this.allData.slice(
        this.page_count1 - 1,
        this.page_count2
      );
      this.data_source.sort = this.sort;
      // this.is_loaded = false;
      // this.getPatients(this.page - 1);
    }
  }

  openNewPatientMedication(patient) {
    this.modalRef = this.modalService.show(ModalNewPatientMedicationComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar Medicamento",
        btn_text: "Adicionar",
        is_new: true,
        isPatient: patient,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getPatients(this.page);
      }
    });
  }

  openPatientMedicationDetail(patient) {
    this.modalRef = this.modalService.show(
      ModalPatientMedicationDetailComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: true,
        ignoreBackdropClick: true,
        class: "modal-dialog-centered modal-dialog",
        containerClass: "",
        animated: false,
        data: {
          title: "Medicamentos",
          btn_text: "Fechar",
          is_new: true,
          isPatient: patient,
        },
      }
    );
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getPatients(this.page);
      }
    });
  }

  newPatient() {
    this.modalRef = this.modalService.show(ModalPatientDetailComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar Paciente",
        btn_text: "Adicionar",
        is_new: true,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getPatients(this.page);
      }
    });
  }

  newCode() {
    this.doctorService.getIntegrationCode().subscribe(
      (data) => {
        if (!data || !data.code) {
          this.openMessage(
            "Erro",
            "Ocorreu um erro ao tentar gerar o código, tente novamente mais tarde!",
            false
          );
        } else {
          this.openMessage(
            "Sucesso",
            `Código gerado com sucesso, informe ao seu paciente para ele realizar o vínculo. (Código: ${data.code})`,
            false
          );
        }
        //console.log(data);
      },
      (err) => {
        this.openMessage(
          "Erro",
          "Ocorreu um erro ao tentar gerar o código, tente novamente mais tarde!",
          false
        );
      }
    );
  }

  seeResponsibles(patient) {{
    this.modalRef = this.modalService.show(ModalPatientResponsablesComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Responsáveis do paciente - " + patient.name,
        btn_text: "Salvar",
        is_new: false,
        patient_clone: patient,
      },
    });
  }}

  editPatient(patient) {
    this.modalRef = this.modalService.show(ModalPatientDetailComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Editar Paciente",
        btn_text: "Salvar",
        is_new: false,
        patient_clone: patient,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getPatients(this.page);
      }
    });
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  getAssocition(patient) {
    const company = localStorage.getItem("company");
    if (
      company &&
      patient &&
      patient.company &&
      Number(company) === Number(patient.company.id)
    ) {
      return patient.company.name;
    }
    return "Independente";
  }

  changeStatus(id, is_active) {
    this.userService.editPatientActived(id, is_active).subscribe(
      (data) => {},
      (error) => {
        this.error(error, "editar");
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o paciente.`,
      msg,
      false
    );
  }

  changeFilter(filter) {
    this.filter = filter;
    this.is_loaded = false;
    this.getPatients(1);
  }

  getStyle(cur) {
    if (this.filter === cur) return "bold";
    return "normal";
  }

  accentFold(inStr) {
    return inStr.replace(
      /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g, 
      function (str, a, c, e, i, n, o, s, u, y, ae) {
        if (a) return 'a';
        if (c) return 'c';
        if (e) return 'e';
        if (i) return 'i';
        if (n) return 'n';
        if (o) return 'o';
        if (s) return 's';
        if (u) return 'u';
        if (y) return 'y';
        if (ae) return 'ae';
      }
    );
  }


  makeFilter() {
    // this.is_loaded = false;
    // this.getPatients(1, this.filterText)
    let data = [];
    if (!this.filterText || !this.filterText.trim().length) {
      data = this.allData;
    } else {
      data = this.allData.filter((item) => {
        return (
          this.accentFold(item.name.toLowerCase()).indexOf(this.accentFold(this.filterText.toLowerCase())) > -1
        );
      });
    }

    this.page = 1;
    this.count_total = this.allData.length;
    this.page_count1 = 1 * this.page_size;
    if (this.page_count1 + (this.page_size - 1) > this.count_total) {
      this.page_count2 = this.count_total;
    } else {
      this.page_count2 = this.page_count1 + (this.page_size - 1);
    }
    // console.log(data);
    data.sort((a, b) => a.name.localeCompare(b.name));
    this.data_source.data = data;
    this.data_source.sort = this.sort;
  }
}
