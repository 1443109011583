import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { CalendarService } from "src/app/service/calendar.service";
import { ProcedureService } from "src/app/service/procedure.service";
import { ModalPatientDetailComponent } from "src/app/modals/modal-patient-detail/modal-patient-detail.component";
import { ServiceService } from "src/app/service/service.service";
import { ModalChoiceComponent } from "src/app/modals/modal-choice/modal-choice.component";

@Component({
  selector: "modal-add-calendar-and-start",
  templateUrl: "./modal-add-calendar-and-start.component.html",
  styleUrls: ["./modal-add-calendar-and-start.component.css"],
})
export class ModalAddCalendarAndStartComponent {
  access_to_telemedicine = false;
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private calendarService: CalendarService,
    private procedureService: ProcedureService,
    private serviceService: ServiceService
  ) {
    const companySettings = JSON.parse(
      localStorage.getItem("company_settings")
    );
    // console.log(companySettings)
    if (companySettings && companySettings["access_to_telemedicine"]) {
      this.access_to_telemedicine = true;
    }
  }

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;
  modalRef3: MDBModalRef;
  title: string = "";
  btn_text: string;
  is_new: boolean = true;

  patient: string;
  date: Date;
  hour: string;
  observation: string;
  validator = new ValidatorUtils();
  loadingPatient: boolean = false;
  loadingDoctor: boolean = false;
  allPatients: any[] = [];
  patients: any[] = [];

  doctor: string;
  allDoctor: any[] = [];
  doctors: any[] = [];

  procedures: any[] = [];
  procedure: any;
  procedure_time: any;
  is_telemedicine: any = false;
  loadingService: boolean = false;
  servicesType: any[] = [];
  allServices: any[] = [];
  serviceType: string;

  ngOnInit() {
    const d = localStorage.getItem("doctor");

    this.getProcedures();
    this.startField();
    this.serviceTypes();
  }

  serviceTypes() {
    this.loadingService = true;
    this.serviceService.getServiceTypes().subscribe(
      (data) => {
        this.servicesType = data;
        this.allServices = data;
        this.loadingService = false;
      },
      (error) => {}
    );
  }

  accentFold(inStr) {
    return inStr.replace(
      /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
      function (str, a, c, e, i, n, o, s, u, y, ae) {
        if (a) return "a";
        if (c) return "c";
        if (e) return "e";
        if (i) return "i";
        if (n) return "n";
        if (o) return "o";
        if (s) return "s";
        if (u) return "u";
        if (y) return "y";
        if (ae) return "ae";
      }
    );
  }

  selectSearchPatient(value: string) {
    this.servicesType = [];
    let filter = this.accentFold(value.toLowerCase());
    for (let i = 0; i < this.allServices.length; i++) {
      let option = this.allServices[i];
      if (this.accentFold(option.name.toLowerCase()).indexOf(filter) >= 0) {
        this.servicesType.push(option);
      }
    }
  }

  onChange() {
    try {
      this.procedure_time = this.procedures.filter(
        (p) => p.id === this.procedure
      )[0].time;
    } catch {}
  }

  startField() {
    const d = new Date();
    this.date = d;
    this.hour =
      (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()) +
      ":" +
      (d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes());
  }

  getProcedures() {
    this.procedureService.getProcedures().subscribe(
      (data) => {
        this.procedures = data;
      },
      (error) => {}
    );
  }

  getForm() {
    let day: any = Number(this.date.getDate());
    var month: any = Number(this.date.getMonth() + 1);
    var year = Number(this.date.getFullYear());
    // const aux = this.date.split("T")[1].split('-').reverse();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    const time = `${year}-${month}-${day}T${this.hour}:00`;
    let body = {
      patient: this.patient,
      observation: this.observation,
      date: time,
      procedure: this.procedure,
      procedure_time: this.procedure_time,
      is_telemedicine: this.is_telemedicine,
      serviceType: this.serviceType,
    };

    return body;
  }

  confirmeLessThan30() {
    this.modalRef3 = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Esse paciente já realizou uma consulta nos últimos 30 dias. Deseja continuar?`,
      },
    });
    this.modalRef3.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.modalRef3.hide();
        this.modalRef2.hide();
        this.create(this.getForm());
      } else {
        this.modalRef3.hide();
        this.modalRef2.hide();
      }
    });
  }

  create(body) {
    this.modalRef2.hide();
    this.openMessage("Por favor, aguarde", "", true);
    this.calendarService.createAndStartRecord(body).subscribe(
      (data) => {
        this.modalRef2.hide();
        this.openMessage("Sucesso!", "Registro criado", false);
        this.action.next(data);
        this.modalRef.hide();
        localStorage.setItem("service", JSON.stringify(data));

        window.location.reload();
      },
      (error) => {
        this.modalRef2.hide();
        this.error(error, "criar");
      }
    );
  }

  async insertRecord() {
    this.openMessage("Por favor, aguarde", "", true);
    var body = this.getForm();
    this.calendarService.check30days(this.patient).subscribe(
      (data) => {
        this.confirmeLessThan30();
      },
      (error) => {
        this.create(body);
      }
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else if (error.error && error.error.detail) {
      msg = error.error.detail;
    } else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi adicionar o registro.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  createRecord() {
    if (this.validator.isEmptyOrNull(this.procedure)) {
      this.openMessage("Por favor,", "informe o tipo de procedimento.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.procedure_time)) {
      this.openMessage("Por favor,", "insira o tempo do procedimento.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.serviceType)) {
      this.openMessage("Por favor,", "escolha o tipo de atendimento.", false);
      return false;
    } else {
      var day = Number(this.date.getDate());
      var month = Number(this.date.getMonth() + 1);
      var year = Number(this.date.getFullYear());

      if (day > 31 || day < 1 || month > 12 || month < 1 || year < 1900) {
        this.openMessage("Por favor,", "insira uma data válida", false);
        return false;
      }
      var hora = Number(this.hour.substring(0, 2));
      var minuto = Number(this.hour.substring(3, 5));
      if (hora >= 24 || hora < 0 || minuto > 59 || minuto < 0) {
        this.openMessage("Por favor,", "insira uma hora válida", false);
        return false;
      }
    }
    this.insertRecord();
  }
}
