import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { CompanyService } from "../service/company.service";
import { MatSort } from "@angular/material/sort";
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from "date-fns";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from "angular-calendar";
import { EventColor } from "calendar-utils";
import { Subject } from "rxjs";
import { CalendarService } from "../service/calendar.service";
import { ModalAddRecordComponent } from "../modals/modal-add-record/modal-add-record.component";
import { ModalChoiceComponent } from "../modals/modal-choice/modal-choice.component";
import { Router } from "@angular/router";

const colors: Record<string, EventColor> = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};
@Component({
  selector: "app-settings-clinic",
  templateUrl: "./settings-clinic.component.html",
  styleUrls: ["./settings-clinic.component.css"],
})
export class SettingsClinicComponent implements OnInit {
  access_to_whatsapp = false;
  access_to_telemedicine = false;
  constructor(
    private companyService: CompanyService,
    private modalService: MDBModalService,
    private datePipe: DatePipe,
    private calendarService: CalendarService,
    public router: Router
  ) {
    const companySettings = JSON.parse(
      localStorage.getItem("company_settings")
    );
    // console.log(companySettings)
    if (companySettings && companySettings["access_to_whatsapp"]) {
      this.access_to_whatsapp = true;
    }
    
    if (companySettings && companySettings["access_to_telemedicine"]) {
      this.access_to_telemedicine = true;
    }
  }

  deleteRecord(event) {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar esse registro?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.calendarService.deleteRecord(event.id).subscribe(
          (data) => {
            this.openMessage("Sucesso!", "Registro deletado", false);
          },
          (error) => {
            this.error(error, "deletar");
          }
        );
      }
    });
  }

  openModalAdd(ev = null) {
    // this.modalRef = this.modalService.show(ModalAddCalendarComponent, {
    //   backdrop: true,
    //   keyboard: true,
    //   focus: true,
    //   show: true,
    //   ignoreBackdropClick: true,
    //   class: "modal-dialog-centered modal-dialog modal-lg modal-size",
    //   containerClass: "",
    //   animated: false,
    //   data: {
    //     title: `${!ev ? "Adicionar" : "Editar"} registro no calendário`,
    //     btn_text: !ev ? "Adicionar" : "Editar",
    //     is_new: true,
    //     event: ev,
    //   },
    // });
    // this.modalRef.content.action.subscribe((result: any) => {
    //   if (result) {
    //     if (!ev) {
    //       this.events = [...this.events, this.createEventData(result)];
    //     } else {
    //       const id = this.events.findIndex((r) => r.id === ev.id);
    //       const copy = this.events;
    //       copy[id] = this.createEventData(result);
    //       this.events = [...copy];
    //     }
    //   }
    // });
  }

  createEventData(d) {
    // return {
    //   id: d.id,
    //   start: new Date(d.start_date),
    //   title:
    //     "(" +
    //     d.start_date.split("T")[1].substring(0, 5) +
    //     ") " +
    //     "Consulta com " +
    //     d.patient.name +
    //     (d.observation ? " - " + d.observation : ""),
    //   color: { ...colors.blue },
    //   actions: this.actions,
    //   observation: d.observation,
    //   cleanDate: d.start_date,
    //   patient: d.patient.id,
    // };
  }

  addEvent(): void {
    this.openModalAdd();

    // this.events = [
    //   ...this.events,
    //   {
    //     title: "New event",
    //     start: startOfDay(new Date()),
    //     end: endOfDay(new Date()),
    //     color: colors.red,
    //     draggable: true,
    //     resizable: {
    //       beforeStart: true,
    //       afterEnd: true,
    //     },
    //   },
    // ];
  }

  company_type = localStorage.getItem("companyType");
  is_loaded = true;

  ngOnInit(): void {}

  modalRef: MDBModalRef;
  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
