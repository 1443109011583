import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TelemedicineService {
  constructor(private http: HttpClient) {}

  getRoomStatus(id: string): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
      
    };
    if (localStorage.getItem("token")) {
      options.headers.set("Authorization", `token ${localStorage.getItem("token")}`)
    }
    return this.http.get<any>(
      AppConfig.path + `core/service/telemedicine/${id}/`,
      options
    );
  }

  getDoctor(id: string): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
      
    };
    if (localStorage.getItem("token")) {
      options.headers.set("Authorization", `token ${localStorage.getItem("token")}`)
    }
    return this.http.get<any>(
      AppConfig.path + `core/service/telemedicine/doctor/${id}/`,
      options
    );
  }

  getCredits(): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
      
    };
    if (localStorage.getItem("token")) {
      options.headers.set("Authorization", `token ${localStorage.getItem("token")}`)
    }
    return this.http.get<any>(
      AppConfig.path + `core/service/telemedicine/credits/`,
      options
    );
  }

}
