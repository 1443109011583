import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";

import { DOCUMENT } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { TelemedicineService } from "../service/telemedicine.service";
import { VideoSDK } from "@videosdk.live/js-sdk";
import * as moment from "moment-timezone";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { WhatsappService } from "../service/whatsapp.service";
import { ModalChoiceComponent } from "../modals/modal-choice/modal-choice.component";
import { ModalTelemedicineCreditsSettingsComponent } from "../settings-clinic/telemedicine-credits-settings/modal-telemedicine-credits-settings/modal-telemedicine-credits-settings.component";

const TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJhZmMxODJjZS1mYTE3LTQ3NDYtYjk2Zi1iMmI0MjZiNWQ1NTYiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTczODY5ODI3OCwiZXhwIjoxNzcwMjM0Mjc4fQ.obFn1aSO3BBsaP7EWfWWWtUbEKnmQ0tI_uWh-DdDVN0";
@Component({
  selector: "app-telemedicine",
  templateUrl: "./telemedicine.component.html",
  styleUrls: ["./telemedicine.component.css"],
})
export class TelemedicineComponent implements OnInit {
  @ViewChild("localVideo") localVideo: ElementRef;
  @ViewChild("remoteVideo") remoteVideo: ElementRef;
  @ViewChild("remoteAudio") remoteAudio: ElementRef;
  title = "videosdk-rtc-angular-javascript-example";

  modalRef: MDBModalRef;
  meeting: any;
  room = null;
  telemedicine_link: any;
  loading = false;
  loadingJoin = false;
  error = null;
  errorDoctor = null;
  service = null;
  microphones = [];
  cameras = [];
  playbacks = [];
  screenShareOn = false;

  localParticipant;
  localParticipantAudio;
  totalParticipants = 0;
  remoteParticipantId = "";
  participants: any = [];

  currentMic = null;
  currentCamera = null;
  currentPlayback = null;

  micEnable = false;
  webCamEnable = false;
  joinPageVideoStream = null;

  cameraPermissionAllowed = true;
  microphonePermissionAllowed = true;
  isDoctor = false;
  startTime = null;
  constructor(
    @Inject(DOCUMENT) document: Document,
    private route: ActivatedRoute,
    private telemedicineService: TelemedicineService,
    private whatsappService: WhatsappService,

    private modalService: MDBModalService
  ) {
    const d = localStorage.getItem("doctor");
    if (d) {
      const aux = JSON.parse(d);
      this.isDoctor = true;
    }

    this.route.params.subscribe((params) => {
      this.telemedicine_link = params["id"];
      this.check();
    });
  }

  check() {
    this.loading = true;
    this.error = null;
    this.telemedicineService.getRoomStatus(this.telemedicine_link).subscribe(
      (data) => {
        console.log(data);
        this.service = data.service;
        this.room = data.room;
        this.loading = false;
        this.setup();
      },
      (err) => {
        this.error = err?.error?.detail ?? "Ocorreu um erro, tente novamente";
        this.loading = false;
      }
    );
  }

  getDate(d) {
    return moment(new Date(d)).format("DD/MM/YYYY - HH:mm");
  }

  ngOnInit(): void {
    //     document.addEventListener("visibilitychange", function() {
    //       if (document.hidden && this.participants && this.participants.length > 0) {
    //         //do whatever you want
    //         console.log("Hidden");
    //       }
    // });
  }

  join() {
    this.meeting.join();
  }

  leave() {
    this.meeting.leave();
  }

  getKeys(map: any) {
    return Array.from(map.keys());
  }

  enableMic() {
    // this.meeting.localParticipant.enableMic();
    try {
      document.getElementById("micButton").style.backgroundColor = "white";
      document.getElementById("muteMic").style.display = "none";
      document.getElementById("unmuteMic").style.display = "unset";
      this.micEnable = true;
    } catch {}
  }

  disableCam() {
    this.meeting.localParticipant.disableWebcam();
  }

  async setup() {
    let checkAudioVideoPermission;

    try {
      checkAudioVideoPermission = await VideoSDK.checkPermissions(
        VideoSDK.Constants.permission.AUDIO_AND_VIDEO
      );
      console.log(
        "check Audio and Video Permissions",
        checkAudioVideoPermission.get(VideoSDK.Constants.permission.AUDIO),
        checkAudioVideoPermission.get(VideoSDK.Constants.permission.VIDEO)
      );
      if (
        checkAudioVideoPermission.get(VideoSDK.Constants.permission.VIDEO) ===
          false ||
        checkAudioVideoPermission.get(VideoSDK.Constants.permission.AUDIO) ===
          false
      ) {
        checkAudioVideoPermission = await VideoSDK.requestPermission(
          VideoSDK.Constants.permission.AUDIO_AND_VIDEO
        );
      }
    } catch (ex) {
      console.log("Error in checkPermissions ", ex);
    }

    console.log(
      "request Audio and Video Permissions",
      checkAudioVideoPermission.get(VideoSDK.Constants.permission.AUDIO),
      checkAudioVideoPermission.get(VideoSDK.Constants.permission.VIDEO)
    );

    if (
      checkAudioVideoPermission.get(VideoSDK.Constants.permission.AUDIO) ===
      false
    ) {
      document.getElementById("micButton").style.display = "none";
      document.getElementById("no-microphone-permission").style.display =
        "block";
    }

    if (
      checkAudioVideoPermission.get(VideoSDK.Constants.permission.VIDEO) ===
      false
    ) {
      document.getElementById("camButton").style.display = "none";
      document.getElementById("no-camera-permission").style.display = "block";
    }

    await this.getMediaDevices();
    await this.enableCam();
    await this.enableMic();

    VideoSDK.on("device-changed", this.deviceChangeEventListener);
  }

  deviceChangeEventListener = async (devices) => {
    //
    await this.getMediaDevices();
    await this.enableCam();
  };

  getMediaDevices = async () => {
    try {
      const checkAudioVideoPermission = await VideoSDK.checkPermissions(
        VideoSDK.Constants.permission.AUDIO_AND_VIDEO
      );

      this.cameraPermissionAllowed = checkAudioVideoPermission.get(
        VideoSDK.Constants.permission.VIDEO
      );
      this.microphonePermissionAllowed = checkAudioVideoPermission.get(
        VideoSDK.Constants.permission.AUDIO
      );

      if (this.cameraPermissionAllowed) {
        let webcams = await VideoSDK.getCameras();
        // console.log(cameraDeviceDropDown);
        this.cameras = webcams;
        webcams.forEach((item) => {
          // if(item.deviceId == "Default")
          // console.log(item);
          if (!this.currentCamera) this.currentCamera = item;
          if (this.currentCamera) {
            if (
              !(
                item.label.toLowerCase().indexOf("frontal") ||
                item.label.toLowerCase().indexOf("front")
              )
            )
              this.currentCamera = item;
          }
        });
      }

      //Method to get all available Microphones.
      //It returns a Promise that is resolved with an array of MicrophoneDeviceInfo objects describing the audio input devices.

      let mics = await VideoSDK.getMicrophones();
      this.microphones = mics;
      for (const item of mics) {
        if (item.deviceId == "default") {
          this.currentMic = item;
        }
      }
      let speakers = await VideoSDK.getPlaybackDevices();
      this.playbacks = speakers;
      for (const item of speakers) {
        if (item.deviceId == "default") {
          this.currentPlayback = item;
        }
      }
    } catch (err) {
      console.log("Error in getting audio or video devices", err);
    }
  };

  changeCam(cam) {
    document.getElementById(this.currentCamera.deviceId).innerHTML = `
    ${this.currentCamera.label}
  `;
    this.currentCamera = cam;
    console.log(this.currentCamera);
    document.getElementById("select-camera").innerHTML = `
  <i class="bi bi-camera-video" style="font-size: 16px;"></i>
  ${this.currentCamera.label}
  `;

    document.getElementById(this.currentCamera.deviceId).innerHTML = `
<i class="bi bi-check2"></i>
${this.currentCamera.label}
`;

    this.toggleWebCam();
    this.toggleWebCam();
  }

  async toggleWebCam() {
    if (this.joinPageVideoStream) {
      document.getElementById("camera-status").style.display = "block";
      (document.getElementById("joinCam") as any).style.backgroundColor =
        "#1C1C1C";
      (document.getElementById("joinCam") as any).srcObject = null;
      document.getElementById("camButton").style.backgroundColor = "#FF5D5D";
      document.getElementById("offCamera").style.display = "unset";
      document.getElementById("onCamera").style.display = "none";
      this.webCamEnable = false;
      const tracks = this.joinPageVideoStream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.joinPageVideoStream = null;
    } else {
      this.enableCam();
    }
  }

  async enableCam() {
    if (this.joinPageVideoStream !== null) {
      const tracks = this.joinPageVideoStream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.joinPageVideoStream = null;
      (document.getElementById("joinCam") as any).srcObject = null;
    }

    if (this.cameraPermissionAllowed) {
      let mediaStream;
      console.log(this.currentCamera?.deviceId);
      try {
        mediaStream = await VideoSDK.createCameraVideoTrack({
          cameraId: this.currentCamera?.deviceId
            ? this.currentCamera.deviceId
            : undefined,
          optimizationMode: "motion",
          multiStream: false,
        });
      } catch (ex) {
        console.log("Exception in enableCam", ex);
      }

      if (mediaStream) {
        document.getElementById("camera-status").style.display = "none";
        this.joinPageVideoStream = mediaStream;
        (document.getElementById("joinCam") as any).srcObject = mediaStream;
        (document.getElementById("joinCam") as any)
          .play()
          .catch((error) =>
            console.log("videoElem.current.play() failed", error)
          );
        (document.getElementById("camera-status") as any).display = "none";
        document.getElementById("camButton").style.backgroundColor = "white";
        document.getElementById("offCamera").style.display = "none";
        document.getElementById("onCamera").style.display = "unset";
        this.webCamEnable = true;
      }
    }
  }

  changeMic(mic) {
    if (
      this.currentMic.deviceId == "communications" ||
      this.currentMic.deviceId == "default"
    ) {
      document.querySelector(
        `#microphoneDeviceDropDown #${this.currentMic.deviceId}`
      ).innerHTML = `
      ${this.currentMic.label}
      `;
    } else {
      document.getElementById(this.currentMic.deviceId).innerHTML = `
      ${this.currentMic.label}
      `;
    }
    this.currentMic = mic;
    console.log(this.currentMic);
    document.getElementById("select-microphone").innerHTML = `
    <i class="bi bi-mic" style="font-size: 14px;"></i>
    ${this.currentMic.label}
    `;

    if (
      this.currentMic.deviceId == "communications" ||
      this.currentMic.deviceId == "default"
    ) {
      document.querySelector(
        `#microphoneDeviceDropDown #${this.currentMic.deviceId}`
      ).innerHTML = `
      <i class="bi bi-check2"></i>
      ${this.currentMic.label}
      `;
    } else {
      document.getElementById(this.currentMic.deviceId).innerHTML = `
      <i class="bi bi-check2"></i>
      ${this.currentMic.label}
      `;
    }
    this.enableMic();
  }
  async toggleMic() {
    if (this.micEnable) {
      document.getElementById("micButton").style.backgroundColor = "#FF5D5D";
      document.getElementById("muteMic").style.display = "unset";
      document.getElementById("unmuteMic").style.display = "none";
      this.micEnable = false;
    } else {
      this.enableMic();
    }
  }

  async changePlayBack(play) {
    if (
      this.currentPlayback.deviceId == "communications" ||
      this.currentPlayback.deviceId == "default"
    ) {
      document.querySelector(
        `#playBackDeviceDropDown #${this.currentPlayback.deviceId}`
      ).innerHTML = `
        ${this.currentPlayback.label}
      `;
    } else {
      document.getElementById(this.currentPlayback.deviceId).innerHTML = `
      ${this.currentPlayback.label}
      `;
    }
    this.currentPlayback = play;
    console.log(this.currentPlayback);
    document.getElementById("select-speaker").innerHTML = `
  <i class="bi bi-volume-up" style="font-size: 17px;"></i>
  ${this.currentPlayback.label}
  `;
    // console.log("before tick ")
    // console.log(document.getElementById(this.currentPlayback.deviceId))
    this.setAudioOutputDevice(this.currentPlayback.deviceId);

    if (
      this.currentPlayback.deviceId == "communications" ||
      this.currentPlayback.deviceId == "default"
    ) {
      document.querySelector(
        `#playBackDeviceDropDown #${this.currentPlayback.deviceId}`
      ).innerHTML = `
    <i class="bi bi-check2"></i>
    ${this.currentPlayback.label}
    `;
    } else {
      document.getElementById(this.currentPlayback.deviceId).innerHTML = `
    <i class="bi bi-check2"></i>
      ${this.currentPlayback.label}
      `;
    }
  }

  setAudioOutputDevice(deviceId) {
    // console.log(deviceId);
    console.log(deviceId);
    const audioTags = document.getElementsByTagName("audio") as any;
    for (let i = 0; i < audioTags.length; i++) {
      console.log(audioTags[i]);
      audioTags.item(i).setSinkId(deviceId);
    }
  }

  // SALA

  checkDoctorInRoom() {
    setTimeout(() => {
      this.joinMeeting();
    }, 2000);
  }

  async joinMeeting() {
    if (this.loadingJoin) return;
    this.loadingJoin = true;
    if (!this.isDoctor) {
      this.telemedicineService.getDoctor(this.telemedicine_link).subscribe(
        (data) => {
          document.getElementById("joinPage").style.display = "none";
          document.getElementById("gridPpage").style.display = "flex";
          this.toggleControls();
          this.startMeeting(this.telemedicine_link);

          this.errorDoctor = null;
          this.loadingJoin = false;
        },
        (err) => {
          this.errorDoctor =
            err?.error?.detail ?? "Ocorreu um erro, tente novamente";
          this.loadingJoin = false;
          this.checkDoctorInRoom();
          return;
        }
      );
    } else {
      this.telemedicineService.getCredits().subscribe(
        (data) => {
          this.toggleControls();
          // this.startMeeting(this.telemedicine_link);
          this.confirmationDoctor();
        },
        (err) => {
          this.errorDoctor =
            err?.error?.detail ?? "Ocorreu um erro, tente novamente";
          this.loadingJoin = false;
          return;
        }
      );
    }

    // document.getElementById("meetingid").value = this.telemedicine_link;
  }

  toggleControls() {
    console.log("from toggleControls");
    if (this.micEnable) {
      console.log("micEnable True");
      document.getElementById("main-pg-unmute-mic").style.display =
        "inline-block";
      document.getElementById("main-pg-mute-mic").style.display = "none";
    } else {
      console.log("micEnable False");
      document.getElementById("main-pg-unmute-mic").style.display = "none";
      document.getElementById("main-pg-mute-mic").style.display =
        "inline-block";
    }

    if (this.webCamEnable) {
      console.log("webCamEnable True");
      document.getElementById("main-pg-cam-on").style.display = "inline-block";
      document.getElementById("main-pg-cam-off").style.display = "none";
    } else {
      console.log("webCamEnable False");
      document.getElementById("main-pg-cam-on").style.display = "none";
      document.getElementById("main-pg-cam-off").style.display = "inline-block";
    }
  }

  async startMeeting(meetingId) {
    if (!this.startTime) {
      this.startTime = new Date();
    }
    if (this.joinPageVideoStream !== null) {
      const tracks = this.joinPageVideoStream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.joinPageVideoStream = null;
      (document.getElementById("joinCam") as any).srcObject = null;
    }

    VideoSDK.off("device-changed", this.deviceChangeEventListener);

    // Meeting config
    VideoSDK.config(TOKEN);
    let customVideoTrack, customAudioTrack;

    if (this.webCamEnable) {
      // console.log(cameraDeviceDropDown.value);
      customVideoTrack = await VideoSDK.createCameraVideoTrack({
        cameraId: this.currentCamera.deviceId
          ? this.currentCamera.deviceId
          : undefined,
        optimizationMode: "motion",
        multiStream: false,
      });
    }

    if (this.micEnable) {
      console.log("Hello microphone called");
      // console.log(microphoneDeviceDropDown.value);
      console.log(this.currentMic?.deviceId);
      customAudioTrack = await VideoSDK.createMicrophoneAudioTrack({
        microphoneId:
          this.currentMic && this.currentMic.deviceId
            ? this.currentMic.deviceId
            : undefined,
        encoderConfig: "high_quality",
        noiseConfig: {
          noiseSuppression: true,
          echoCancellation: true,
          autoGainControl: true,
        },
      });
    }

    // Meeting Init
    this.meeting = VideoSDK.initMeeting({
      meetingId: meetingId, // required
      name: this.isDoctor ? "Doctor" : "Patient", // required
      micEnabled: this.micEnable, // optional, default: true
      webcamEnabled: this.webCamEnable, // optional, default: true
      maxResolution: "hd", // optional, default: "hd"
      customCameraVideoTrack: customVideoTrack,
      customMicrophoneAudioTrack: customAudioTrack,
    });

    this.participants = this.meeting.participants;
    console.log("meeting obj : ", this.meeting);
    // Meeting Join
    this.meeting.join();

    //create Local Participant
    this.createLocalParticipant();

    //add yourself in participant list
    if (this.totalParticipants != 0)
      this.addParticipantToList({
        id: this.meeting.localParticipant.id,
        displayName: "You",
      });

    // Setting local participant stream
    this.meeting.localParticipant.on("stream-enabled", (stream) => {
      this.setTrack(
        stream,
        this.localParticipantAudio,
        this.meeting.localParticipant,
        true
      );
      console.log("webcam used : ", this.meeting.selectedCameraDevice);
      console.log("microphone used : ", this.meeting.selectedMicrophoneDevice);
    });

    this.meeting.localParticipant.on("stream-disabled", (stream) => {
      if (stream.kind == "video") {
        document.getElementById("main-pg-cam-on").style.display = "none";
        document.getElementById("main-pg-cam-off").style.display =
          "inline-block";
      }
      if (stream.kind == "audio") {
        document.getElementById("main-pg-unmute-mic").style.display = "none";
        document.getElementById("main-pg-mute-mic").style.display =
          "inline-block";
      }
      console.log("webcam used : ", this.meeting.selectedCameraDevice);
      console.log("microphone used : ", this.meeting.selectedMicrophoneDevice);
    });

    this.meeting.on("meeting-joined", () => {
      this.meeting.pubSub.subscribe("CHAT", (data) => {
        let { message, senderId, senderName, timestamp } = data;
        const chatBox = document.getElementById("chatArea");
        const chatTemplate = `
            <div style="margin-bottom: 10px; ${
              this.meeting.localParticipant.id == senderId &&
              "text-align : right"
            }">
              <span style="font-size:12px;">${senderName}</span>
              <div style="margin-top:5px">
                <span style="background:${
                  this.meeting.localParticipant.id == senderId
                    ? "grey"
                    : "crimson"
                };color:white;padding:5px;border-radius:8px">${message}<span>
              </div>
            </div>
            `;
        chatBox.insertAdjacentHTML("beforeend", chatTemplate);
      });
    });

    this.meeting.on("meeting-left", () => {
      console.log("SAIUUUU");
      window.location.reload();
      document.getElementById("join-page").style.display = "flex";
    });

    // Other participants
    this.meeting.on("participant-joined", (participant) => {
      this.totalParticipants++;
      let videoElement = this.createVideoElement(participant.id);
      console.log("Video Element Created");
      let resizeObserver = new (window as any).ResizeObserver(() => {
        participant.setViewPort(
          videoElement.offsetWidth,
          videoElement.offsetHeight
        );
      });
      resizeObserver.observe(videoElement);
      let audioElement = this.createAudioElement(participant.id);
      this.remoteParticipantId = participant.id;

      participant.on("stream-enabled", (stream) => {
        this.setTrack(stream, audioElement, participant, false);
      });
      document.getElementById("videoContainer").appendChild(videoElement);
      console.log("Video Element Appended");
      document.getElementById("videoContainer").appendChild(audioElement);
      this.addParticipantToList(participant);
      this.setAudioOutputDevice(this.currentPlayback.deviceId);
    });

    // participants left
    this.meeting.on("participant-left", (participant) => {
      this.totalParticipants--;
      let vElement = document.getElementById(`v-${participant.id}`);
      vElement.parentNode.removeChild(vElement);

      let aElement = document.getElementById(`a-${participant.id}`);
      aElement.parentNode.removeChild(aElement);
      //remove it from participant list participantId;
      // document.getElementById(`p-${participant.id}`).remove();
    });

    //recording events
    // this.meeting.on("recording-started", () => {
    //   console.log("RECORDING STARTED EVENT");
    //   btnStartRecording.style.display = "none";
    //   btnStopRecording.style.display = "inline-block";
    // });
    // meeting.on("recording-stopped", () => {
    //   console.log("RECORDING STOPPED EVENT");
    //   btnStartRecording.style.display = "inline-block";
    //   btnStopRecording.style.display = "none";
    // });

    this.meeting.on("presenter-changed", (presenterId) => {
      if (presenterId) {
        document.getElementById("videoScreenShare").style.display =
          "inline-block";
        this.screenShareOn = true;
      } else {
        console.log(presenterId);
        document.getElementById("videoScreenShare").removeAttribute("src");
        (document.getElementById("videoScreenShare") as any).pause();
        (document.getElementById("videoScreenShare") as any).load();
        document.getElementById("videoScreenShare").style.display = "none";

        document.getElementById("videoScreenShare").style.color = "white";
        this.screenShareOn = false;
        console.log(`screen share on : ${this.screenShareOn}`);
      }
    });

    //add DOM Events
    this.addDomEvents();
  }

  addParticipantToList({ id, displayName }) {
    let participantTemplate = document.createElement("div");
    participantTemplate.className = "row";
    participantTemplate.style.padding = "4px";
    participantTemplate.style.marginTop = "1px";
    participantTemplate.style.marginLeft = "7px";
    participantTemplate.style.marginRight = "7px";
    participantTemplate.style.borderRadius = "3px";
    participantTemplate.style.border = "1px solid rgb(61, 60, 78)";
    participantTemplate.style.backgroundColor = "rgb(0, 0, 0)";

    //icon
    let colIcon = document.createElement("div");
    colIcon.className = "col-2";
    colIcon.innerHTML = "Icon";
    participantTemplate.appendChild(colIcon);

    //name
    let content = document.createElement("div");
    colIcon.className = "col-3";
    colIcon.innerHTML = `${displayName}`;
    participantTemplate.appendChild(content);
    // participants.push({ id, displayName });

    document
      .getElementById("participantsList")
      .appendChild(participantTemplate);
    document
      .getElementById("participantsList")
      .appendChild(document.createElement("br"));
  }

  //add button events once meeting is created
  addDomEvents() {
    // mic button event listener
    document
      .getElementById("main-pg-unmute-mic")
      .addEventListener("click", () => {
        console.log("Mic-on pressed");
        this.meeting.muteMic();
      });

    document
      .getElementById("main-pg-mute-mic")
      .addEventListener("click", async () => {
        console.log("Mic-f pressed");
        if (this.microphonePermissionAllowed) {
          this.meeting.unmuteMic();
        } else {
          console.log("Audio : Permission not granted");
        }
      });

    document
      .getElementById("main-pg-cam-on")
      .addEventListener("click", async () => {
        this.meeting.disableWebcam();
      });

    document
      .getElementById("main-pg-cam-off")
      .addEventListener("click", async () => {
        if (this.cameraPermissionAllowed) {
          this.meeting.enableWebcam();
        } else {
          console.log("Camera : Permission not granted");
        }
      });

    // screen share button event listener
    document
      .getElementById("btnScreenShare")
      .addEventListener("click", async () => {
        if (document.getElementById("btnScreenShare").style.color == "grey") {
          this.meeting.disableScreenShare();
        } else {
          this.meeting.enableScreenShare();
        }
      });

    //send chat message button
    document.getElementById("btnSend").addEventListener("click", async () => {
      const message = (document.getElementById("txtChat") as any).value;
      console.log("publish : ", message);
      (document.getElementById("txtChat") as any).value = "";
      this.meeting.pubSub
        .publish("CHAT", message, { persist: true })
        .then((res) => console.log(`response of publish : ${res}`))
        .catch((err) => console.log(`error of publish : ${err}`));
      // meeting.sendChatMessage(JSON.stringify({ type: "chat", message }));
    });

    // // //leave Meeting Button
    // $("#leaveCall").click(async () => {
    //   participants = new Map(meeting.participants);
    //   meeting.leave();
    // });

    // //end meeting button
    // $("#endCall").click(async () => {
    //   meeting.end();
    // });
  }

  createLocalParticipant() {
    this.totalParticipants++;
    this.localParticipant = this.createVideoElement(
      this.meeting.localParticipant.id
    );
    this.localParticipantAudio = this.createAudioElement(
      this.meeting.localParticipant.id
    );
    // console.log("localPartcipant.id : ", localParticipant.className);
    // console.log("meeting.localPartcipant.id : ", meeting.localParticipant.id);
    document
      .getElementById("videoContainer")
      .appendChild(this.localParticipant);
  }

  createVideoElement(pId) {
    let division;
    division = document.createElement("div");
    division.setAttribute("id", "video-frame-container");
    division.className = `v-${pId}`;
    let videoElement = document.createElement("video") as any;
    videoElement.classList.add("video-frame");
    videoElement.setAttribute("id", `v-${pId}`);
    videoElement.setAttribute("playsinline", true);
    //videoElement.setAttribute('height', '300');
    // videoElement.setAttribute("width", "300");
    division.appendChild(videoElement);
    return videoElement;
  }

  // creating audio element
  createAudioElement(pId) {
    let audioElement = document.createElement("audio");
    audioElement.setAttribute("autoPlay", "false");
    audioElement.setAttribute("playsInline", "true");
    audioElement.setAttribute("controls", "false");
    audioElement.setAttribute("id", `a-${pId}`);
    return audioElement;
  }

  setTrack(stream, audioElement, participant, isLocal) {
    if (stream.kind == "video") {
      console.log("setTrack called...");
      if (isLocal) {
        document.getElementById("main-pg-cam-off").style.display = "none";
        document.getElementById("main-pg-cam-on").style.display =
          "inline-block";
      }
      const mediaStream = new MediaStream();
      mediaStream.addTrack(stream.track);
      let videoElm = document.getElementById(`v-${participant.id}`) as any;
      videoElm.srcObject = mediaStream;
      videoElm
        .play()
        .catch((error) =>
          console.error("videoElem.current.play() failed", error)
        );
      participant.setViewPort(videoElm.offsetWidth, videoElm.offsetHeight);
    }
    if (stream.kind == "audio") {
      if (isLocal) {
        document.getElementById("main-pg-mute-mic").style.display = "none";
        document.getElementById("main-pg-unmute-mic").style.display =
          "inline-block";
        return;
      }
      const mediaStream = new MediaStream();
      mediaStream.addTrack(stream.track);
      audioElement.srcObject = mediaStream;
      audioElement
        .play()
        .catch((error) => console.error("audioElem.play() failed", error));
    }
    if (stream.kind == "share" && !isLocal) {
      this.screenShareOn = true;
      const mediaStream = new MediaStream();
      mediaStream.addTrack(stream.track);
      (document.getElementById("videoScreenShare") as any).srcObject =
        mediaStream;
      (document.getElementById("videoScreenShare") as any)
        .play()
        .catch((error) =>
          console.error("videoElem.current.play() failed", error)
        );
      document.getElementById("videoScreenShare").style.display =
        "inline-block";
      document.getElementById("btnScreenShare").style.color = "grey";
    }
  }

  openParticipantWrapper() {
    document.getElementById("participants").style.width = "350px";
    document.getElementById("gridPpage").style.marginRight = "350px";
    document.getElementById("ParticipantsCloseBtn").style.visibility =
      "visible";
    document.getElementById("totalParticipants").style.visibility = "visible";
    document.getElementById(
      "totalParticipants"
    ).innerHTML = `Participants (${this.totalParticipants})`;
  }

  closeParticipantWrapper() {
    document.getElementById("participants").style.width = "0";
    document.getElementById("gridPpage").style.marginRight = "0";
    document.getElementById("ParticipantsCloseBtn").style.visibility = "hidden";
    document.getElementById("totalParticipants").style.visibility = "hidden";
  }

  openChatWrapper() {
    document.getElementById("chatModule").style.width = "350px";
    document.getElementById("gridPpage").style.marginRight = "350px";
    document.getElementById("chatCloseBtn").style.visibility = "visible";
    document.getElementById("chatHeading").style.visibility = "visible";
    document.getElementById("btnSend").style.display = "inline-block";
  }

  closeChatWrapper() {
    document.getElementById("chatModule").style.width = "0";
    document.getElementById("gridPpage").style.marginRight = "0";
    document.getElementById("chatCloseBtn").style.visibility = "hidden";
    document.getElementById("btnSend").style.display = "none";
  }

  endCall() {
    this.meeting.end();
  }

  leaveCall() {
    this.participants = new Map(this.meeting.participants);
    this.meeting.leave();
  }

  pipWindowRef = null;
  async pictureInPicture() {
    if ("pictureInPictureEnabled" in document) {
      //Creating a Canvas which will render our PiP Stream
      const source = (document as any).createElement("canvas");
      const ctx = source.getContext("2d");

      //Create a Video tag which we will popout for PiP
      const pipVideo = (document as any).createElement("video") as any;
      this.pipWindowRef = { current: null };
      this.pipWindowRef.current = pipVideo;
      pipVideo.autoplay = true;

      //Creating stream from canvas which we will play
      const stream = source.captureStream();
      pipVideo.srcObject = stream;

      //Do initial Canvas Paint
      drawCanvas();

      //When Video is ready we will start PiP mode
      pipVideo.onloadedmetadata = () => {
        pipVideo.requestPictureInPicture();
      };
      await pipVideo.play();
      //When the PiP mode starts, we will start drawing canvas with PiP view
      pipVideo.addEventListener("enterpictureinpicture", (event) => {
        drawCanvas();
      });

      //When PiP mode exits, we will dispose the track we created earlier
      pipVideo.addEventListener("leavepictureinpicture", (event) => {
        this.pipWindowRef.current = null;
        pipVideo.srcObject.getTracks().forEach((track) => track.stop());
      });

      //This will draw all the video elements in to the Canvas
      function drawCanvas() {
        //Getting all the video elements in the document
        const _videos = document.querySelectorAll("video");
        try {
          const videos = [];
          for (let i = 2; i < _videos.length; i++) {
            videos.push(_videos[i]);
          }
          //Perform initial black paint on the canvas
          ctx.fillStyle = "black";
          ctx.fillRect(0, 0, source.width, source.height);

          //Drawing the participant videos on the canvas in the grid format
          const rows = videos.length > 2 ? 2 : videos.length > 0 ? 1 : 0;
          const columns = videos.length < 2 ? 1 : videos.length < 5 ? 2 : 3;
          for (let i = 0; i < rows; i++) {
            for (let j = 0; j < columns; j++) {
              if (j + i * columns <= videos.length || videos.length == 1) {
                ctx.drawImage(
                  videos[j + i * columns],
                  j < 1 ? 0 : source.width / (columns / j),
                  i < 1 ? 0 : source.height / (rows / i),
                  source.width / columns,
                  source.height / rows
                );
              }
            }
          }
        } catch (error) {
          // console.log('Error')
        }

        //If pip mode is on, keep drawing the canvas when ever new frame is requested
        if ((document as any).pictureInPictureElement === pipVideo) {
          requestAnimationFrame(drawCanvas);
        }
      }
    } else {
      this.openMessage(
        "Erro",
        "Seu navegador não suporta o modo picture-in-picture",
        true
      );
      return;
    }
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  isShowWhatsapp() {
    if (!this.isDoctor) return;
    const companySettings = JSON.parse(
      localStorage.getItem("company_settings")
    );
    if (companySettings && companySettings["access_to_whatsapp"]) {
      return true;
    }
  }
  sendMessageWhatsapp() {
    const message = `Olá, ${this.service.patient.name}. Estou te aguardando para sua consulta on-line através do link: https://medicalangel.com.br/telemedicine/${this.telemedicine_link}.`;
    const number = this.service.patient.phone
      ? this.service.patient.phone
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .split(" ")
          .join("")
      : "";
    this.whatsappService.sendMessage(message, "+55" + number).subscribe({
      next: (data) => {
        console.log(data);
        this.openMessage("Sucesso", "Messagem enviada", true);
      },
      error: (err) => {
        const msg =
          err?.error?.detail ??
          "Ocorreu um erro ao enviar, solicite ao administrador da sua empresa para configurar corretamente a conexão";
        console.log(err);
        this.openMessage("Erro", msg, true);
      },
    });
  }

  getDiffTime() {
    const seconds = moment(new Date()).diff(this.startTime, "seconds");
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
  }

  confirmationDoctor() {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Ao iniciar a chamada será iniciado o cronômetro de tempo e será descontado dos créditos de sua empresa. Deseja iniciar?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        document.getElementById("joinPage").style.display = "none";
        document.getElementById("gridPpage").style.display = "flex";
        this.startMeeting(this.telemedicine_link);
        this.toggleControls();

        this.loadingJoin = true;
        this.errorDoctor = null;
      } else {
        this.loadingJoin = false;
      }
    });
  }

  buyCredits() {
    this.modalRef = this.modalService.show(
      ModalTelemedicineCreditsSettingsComponent,
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: true,
        ignoreBackdropClick: true,
        class: "modal-dialog-centered modal-dialog modal-lg modal-size",
        containerClass: "",
        animated: false,
        data: {
          title: `Adicionar créditos`,
        },
      }
    );
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.errorDoctor = null;
      }
    });
  }
}
