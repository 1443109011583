import { HomeComponent } from "./landing/home/home.component";
import { LandingComponent } from "./landing/landing.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DoctorsComponent } from "./doctors/doctors.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { InformationsComponent } from "./informations/informations.component";
import { LoginComponent } from "./login/login.component";
import { MedicationComponent } from "./medication/medication.component";
import { NavbarScreenComponent } from "./navbar/navbar.component";
import { PatientComponent } from "./patient/patient.component";
import { PatientsComponent } from "./patients/patients.component";
import { TermsComponent } from "./terms/terms.component";
import { AboutComponent } from "./landing/about/about.component";
import { ContactUsComponent } from "./landing/contact-us/contact-us.component";
import { HomePatientComponent } from "./landing/home-patient/home-patient.component";
import { HomeDoctorComponent } from "./landing/home-doctor/home-doctor.component";
import { HomecareComponent } from "./landing/homecare/homecare.component";
import { PlansComponent } from "./landing/plans/plans.component";
import { PartnersComponent } from "./landing/partners/partners.component";
import { FormsComponent } from "./forms/forms.component";
import { DeleteAccountComponent } from "./delete-account/delete-account.component";
import { TermsRawComponent } from "./terms/terms-raw.component";
import { ReportDayComponent } from "./report-day/report_day.component";
import { RegisterComponent } from "./register/register.component";
import { VinculateDoctorComponent } from "./vinculate_doctor/vinculate_doctor.component";
import { AdminComponent } from "./admin/admin.component";
import { NotAllowedComponent } from "./not-allowed/not-allowed.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CorePlanComponent } from "./core-plan/core-plan.component";
import { ReportDayFormsComponent } from "./report-day-forms/report_day-forms.component";
import { ScoreAnswersComponent } from "./scoreanswers/scoreanswers.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { SettingsClinicComponent } from "./settings-clinic/settings-clinic.component";
import { TelemedicineComponent } from "./telemedicine/telemedicine.component";

const routes: Routes = [
  // hide later
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: "reset-password", component: ForgotPasswordComponent },
  { path: "delete-account", component: DeleteAccountComponent },
  {
    path: "home",
    component: NavbarScreenComponent,
    children: [
      { path: "", redirectTo: "patients", pathMatch: "full" },
      { path: "patients", component: PatientsComponent },
      { path: "dashboard", component: DashboardComponent },
      { path: "patient/:id", component: PatientComponent },
      { path: "doctors", component: DoctorsComponent },
      { path: "informations", component: InformationsComponent },
      { path: "form", component: FormsComponent },
      { path: "form/:id/:name", component: FormsComponent },
      { path: "scales", component: ScoreAnswersComponent },
      { path: "core-plan", component: CorePlanComponent },
      { path: "report_day", component: ReportDayComponent },
      { path: "report_day_forms", component: ReportDayFormsComponent },
      { path: "medication", component: MedicationComponent },
      { path: "admin", component: AdminComponent },
      { path: "not-allowed", component: NotAllowedComponent },
      { path: "calendar", component: CalendarComponent },
      { path: "settings-clinic", component: SettingsClinicComponent },
    ],
  },
  { path: "register", component: RegisterComponent },
  { path: "login", component: LoginComponent },
  { path: "terms", component: TermsComponent },
  { path: "terms_privacy", component: TermsComponent },
  { path: "terms_raw", component: TermsRawComponent },
  { path: "vinculate_doctor", component: VinculateDoctorComponent },
  { path: "telemedicine/:id", component: TelemedicineComponent },
  {
    path: "",
    component: LandingComponent,
    children: [
      { path: "", component: HomeComponent },
      { path: "about", component: AboutComponent },
      { path: "contact-us", component: ContactUsComponent },
      { path: "patient", component: HomePatientComponent },
      { path: "doctor", component: HomeDoctorComponent },
      { path: "homecare", component: HomecareComponent },
      // { path: 'plans', component: PlansComponent },
      { path: "partners", component: PartnersComponent },
    ],
  },
  { path: "**", redirectTo: "home" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
